@mixin generate-header-scroll-button-styles {
  .header {
    background-color: getHeaderColors(header-background);

    padding: calculateRem(10px);

    // Center the nav items on larger screens
    display: flex;
    // justify-content: center;

    .navigation {
      width: 100%;
      .navigation_language {
        display: flex;
        justify-content: space-between;
        padding-right: calculateRem(20px);
      }
    }

    .image_container {
      width: 200px;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 1024px) {
      justify-content: flex-start;
    }
    .navigation_up {
      display: flex;
      list-style: none;
      gap: 10px;
      a {
        color: #5d5252 !important;
      }
    }
    .nav {
      list-style: none;
      margin: 0;
      padding: 0;

      &__list {
        @include flex();
        flex-direction: column;
        a {
          color: getHeaderColors(header-text-color);
          text-decoration: none;
        }
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        &__list {
          flex-direction: row;
          gap: calculateRem(20px);
        }
      }

      &__link {
        text-decoration: none;
        color: white;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button {
    text-decoration: none;
  }
  .button_active {
    color: getHeaderSelectColors(header-select-text-color);
  }
  .button_showActive {
    color: #5d5252;
  }
}
