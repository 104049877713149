body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.slick-active button {
  background: rgb(110, 193, 228) !important;
  color: rgb(110, 193, 228) !important;
}

* {
  box-sizing: border-box;
}
/* .MuiInputBase-root {
  min-height: 75px;
} */
.MuiInputBase-input {
  color: #878787 !important;
}

#tableCell {
  vertical-align: top;
}
td {
  vertical-align: top !important;
}

li {
  list-style: none !important;
}
.image {
  width: 100%;
  height: 100%;
}
