@mixin generate-header-scroll-styles {
  .dropdown {
    position: relative;

    button {
      background-color: transparent;
      color: getHeaderSelectColors(header-select-text-color);
      border: none;
      cursor: pointer;
      font-size: calculateRem(10px);
    }
    a {
      color: getHeaderSelectColors(header-select-text-color);
      text-decoration: none;
      white-space: nowrap;
    }
    ul {
      position: absolute;
      top: 100%;
      left: 0;
      border-radius: 8px;
      gap: 20px;
      display: flex;
      align-items: flex-start;
      padding: 10px;
      flex-direction: column;
      width: fit-content;
      z-index: 999;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      padding-top: 10px;
      margin: 0;
      list-style: none;
      background-color: getHeaderSelectColors(header-select-background);
      // border: 1px solid #ccc;
      border-top: none;
      transition: all 0.3s ease;

      button {
        &:first-child {
          margin-top: 10px;
        }
        color: getHeaderSelectColors(header-select-text-color);
        text-decoration: none;
        white-space: nowrap;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          color: getHeaderSelectColors(header-select-hovered-text-color);
        }
      }
    }
  }
}
