@mixin generate-nav-link-styles {
  .nav__item {
    cursor: pointer;
    list-style: none;

    a {
      text-decoration: none;
      font-weight: 500;
      color: getLinkColors(link-text-color);

      &:hover {
        color: getLinkColors(link-text-color-hover);
      }
    }
  }
  .navLink {
    text-decoration: none;
    color: #5d5252;
    &_active {
      color: #1976d2 !important;
    }
    &_mobile {
      display: flex;
      width: auto;
      color: #5d5252;
      padding: 8px 16px;
    }
    &_isSowActive {
      color: #5d5252 !important;
    }
  }
}
