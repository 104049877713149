@mixin generate-slider-styles {
  .dots {
    list-style: none;
    display: flex !important;
    gap: 15px;
    justify-content: center;
    li {
      button {
        border: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: gray;
        color: gray;
        cursor: pointer;
      }
    }
  }
}
