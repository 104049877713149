@mixin generate-navbar-scroll-button-styles {
  .navbar-scroll--button {
    a {
      text-decoration: none;
      color: black;
    }
    &__active {
      a {
        color: #6ec1e4;
      }
    }
  }
}
