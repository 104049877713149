@mixin generate-language-select-styles {
  .select {
    width: calculateRem(100px);

    img {
      border-radius: 4px;
    }
    border: none;
    div {
      border: none;
    }
  }
  .CustomOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calculateRem(2px) calculateRem(10px);
    cursor: pointer;
    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .flag {
    border-radius: 8px;
  }
}
